<template>
  <div>
    <b-form-group
      id="code-group"
      label="Code"
      label-for="code"
    >
      <b-form-input
        id="code"
        v-model="model.code"
        placeholder="Code"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="description-group"
      label="Description"
      label-for="description"
    >
      <b-form-textarea
        id="description"
        placeholder="Description"
        v-model="model.description"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'SiglesourceNew',
    data(){
      return {
        model: {
          code: '',
          description: '',
          sigleAuthorHists: [],
          sigleEditions: [],
          sigleStudies: [],
          sigleTexts: []
        }
      }
    },
    updated(){
      HelpStore.item = this.model
    }
  }
</script>
