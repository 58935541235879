<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Search Sigle Entities'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.bib.siglesource.add')" class="float-right" v-b-modal.modal-new-siglesource>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new sigle source
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-siglesource" title="New Sigle Source" ok-title="Save" @ok="save" size="lg">
            <SiglesourceNew />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @click="openDetails"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 100px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import SiglesourceNew from '@/components/SiglesourceNew.vue'
  import HelpStore from '@/store/helpstore.js'
  import tdColAction from "@/components/tdColAction"
  import config from '@/config.js'

  export default {
    name: 'SigleEntitiesList',
    data(){
      return {
        columns: [
          { title: this.$t('SigleLegam'), field: 'objectSigle', sortable: true, searchable: true },
          { title: this.$t('Label'), field: 'labels', sortable: true, searchable: true },
          { title: this.$t('AltSigle'), field: 'alternativeSigle', sortable: true, searchable: true },
          { title: this.$t('SigleSource'), field: 'siglesourceCode', sortable: true, searchable: true, transformData: function (row) {
              let retval = ''
              if (row.siglesourceCode) {
                // TODO: Find better way to do this
                retval = `
                  ${row.siglesourceCode}
                  <a href="#/searchsigleentity/${row.siglesourceCode}" class="">
                    <button type="button" class="btn mr-1 btn-light btn-sm"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="book" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-book mr-1 b-icon bi"><g><path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"></path></g></svg><!----></button>
                  </a>
                `
              }
              return retval
            }
          },
          { title: this.$t('Entity'), field: 'objectType', sortable: true, searchable: true },
          {
            title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              let detailsView = {
                'Authorhist': 'HistoricalAuthorDetails',
                'Edition': 'EditionDetails',
                'Study': 'StudyDetails',
                'Text': 'TextDetails',
                'Manuscript': 'ManuscriptDetails'
              }[row.objectType]
              let retval = []
                retval.push(
                  {text: null, icon: 'list-ul', id: row.objectId || row.id, to: {
                    name: detailsView,
                    params: {
                      slug: row.objectSlug ? row.objectSlug : '-',
                      id: row.objectId || row.id
                    }
                  }}
                )
              // }
              return retval
            }
          }
        ],
        columnsPI: [
          { title: 'Sigle LEGaM', field: 'objectSigle', sortable: true, searchable: true },
          { title: 'Alternative Sigle', field: 'alternativeSigle', sortable: true, searchable: true },
          { title: 'Sigle source', field: 'siglesourceCode', sortable: true, searchable: true, transformData: function (row) {
              let retval = ''
              if (row.siglesourceCode) {
                // TODO: Find better way to do this
                retval = `
                  ${row.siglesourceCode}
                  <a href="#/searchsigleentity/${row.siglesourceCode}" class="">
                    <button type="button" class="btn mr-1 btn-light btn-sm"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="book" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-book mr-1 b-icon bi"><g><path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"></path></g></svg><!----></button>
                  </a>
                `
              }
              return retval
            }
          },
          { title: 'Entity', field: 'objectType', sortable: true, searchable: true },
          { title: 'Label', field: 'labels', sortable: true, searchable: false }
        ],
        url: '/siglesources/datatables',
        key: 1,
        searchableByColumn: true,
        config: config,
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      SiglesourceNew,
      BibLabelSelectPI,
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.biblabels.length) {
          data = data.filter(row => this.userSettingsPI.biblabels.includes(row.labels[0]))
        }
        return data
      },
      save(){
        this.$store.dispatch('saveSiglesource', HelpStore.item).then((response) => {
          router.push(`/siglesource/${response.data.code}`)
        })
      },
      openDetails(data) {
        let urlBase = {
          'Authorhist': 'historicalAuthor',
          'Edition': 'edition',
          'Study': 'study',
          'Text': 'text',
          'Manuscript': 'manuscript'
        }[data.objectType]
        router.push(`/${urlBase}/-/${data.id}`)
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
