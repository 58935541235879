<template>
  <div class="biblable mt-3" id="select-bib-label">
    <div class="title">Labels</div>
    <b-form-group>
      <b-form-checkbox-group size="sm" v-model="selected" :options="biblabelsOptions" button-variant="secondary"
        buttons></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<style scoped>
.biblable {
  margin-top: 11px !important;
}

div.title {
  margin-right: 4px;
}

*>>>.btn-secondary {
  background-color: #e8e8e8 !important;
  color: #000;
  border-color: #b8b8b8 !important;
}
</style>

<script>
// import config from '@/config.js'
import { mapState } from 'vuex'
// import Multiselect from 'vue-multiselect'

export default {
  name: 'BibLableSelectPI',
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapState({
      biblabels: state => state.common.biblabels,
      userSettingsPI: state => state.user.settingsPI,
    }),
    biblabelsOptions() {
      return this.biblabels.map(biblabel => {
        return {
          'text': biblabel.label,
          'value': biblabel.label
        }
      })
    },
  },
  mounted() {
    this.selected = this.biblabels
      .filter(biblabel => this.userSettingsPI.biblabels.includes(biblabel.label))
      .map(biblabel => biblabel.label)
  },
  watch: {
    selected() {
      this.$store.dispatch(
        'setUserSettingsPI',
        { property: 'biblabels', value: this.selected }
      )
    },
  },
}
</script>
